import './App.css';
import RichTextEditor from './Draft';

function App() {
  return (
    <div className="App">
      {/* <LinkChecker/> */}
      <RichTextEditor/>
    </div>
  );
}

export default App;
